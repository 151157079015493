body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #5D5FEF;
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 32px;
  color: #FFF;
}

.input {
  height: 40px;
  width: 80%;
  border: 1px solid #9B9B9B;
  border-radius: 4px;
  padding: 0 10px;
  margin-bottom: 16px;
  background-color: #FFF;
}

.button {
  background: linear-gradient(162deg, rgba(71,219,246,0.8687850140056023) 5%, rgba(100,71,246,1) 25%, rgba(140,71,209,1) 45%, rgba(246,71,111,1) 74%, rgba(189,246,71,0.7987570028011204) 94%, rgba(246,71,111,1) 95%);
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  margin-top: 16px;
  cursor: pointer;
  border-radius: 10px;
}

.button:disabled {
  border-radius: 10px;
  background: #484866;
}

.buttonText {
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
}

.chatResponseTable {
  max-width: 70%;
  overflow-y: auto;
  height:50%;
}

tr {
  color: rgb(80, 218, 226);
  font-size:12px;
}

td {
  padding-top: 20px;
  border-bottom: 1px solid rgb(178, 105, 226);
  padding-bottom: 20px;
  font-weight: 500;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
}

tr:nth-child(1) { 
  color: #350970; 
  font-size:16px;
  font-weight: 700;
  padding-bottom: 20px;
}

.gptQuery {
  color: rgb(237, 212, 47);
  font-weight: bolder;
}
